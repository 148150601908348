import React, { Component } from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #1F5697;
  }
`;

const slide = keyframes`
  from {
    background-position: 0, 0, 0 , 0;
  }

  to {
    background-position: 52px, -116px, 148px, -212px;
  }
`;

const Tag = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(
      90deg,
      transparent 0%,
      rgba(0, 0, 0, 0.07) 5%,
      rgba(0, 0, 0, 0.07) 50%,
      transparent 55%
    ),
    linear-gradient(
      90deg,
      transparent 0%,
      rgba(0, 0, 0, 0.13) 5%,
      rgba(0, 0, 0, 0.13) 50%,
      transparent 55%
    ),
    linear-gradient(
      90deg,
      transparent 45%,
      rgba(0, 0, 0, 0.17) 55%,
      rgba(0, 0, 0, 0.17) 95%,
      transparent 100%
    ),
    linear-gradient(
      90deg,
      transparent 45%,
      rgba(0, 0, 0, 0.19) 55%,
      rgba(0, 0, 0, 0.19) 95%,
      transparent 100%
    );
  background-size: 52px, 116px, 148px, 212px;
  animation: ${slide} 20s linear infinite;
  z-index: -1;
`;

class Background extends Component {
  render() {
    return (
      <Tag>
        <GlobalStyle />
      </Tag>
    );
  }
}

export default Background;
