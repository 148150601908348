import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Tag = styled.a`
  display: block;
  color: inherit;
  text-decoration: none;
  position: relative;
  padding: 1em 1em 1em 2.7em;
  max-width: 15em;
  border: 1px solid transparent;

  :hover {
    text-decoration: none;
    background: rgba(255, 255, 255, 0.3);
  }
  :focus {
    border-color: white;
    outline: none;
  }
`;

const IconWrapper = styled.div`
  width: 1em;
  height: 1em;
  position: absolute;
  left: 1em;
  top: 1em;
  opacity: 0.5;
`;

class Link extends Component {
  static propTypes = {
    href: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.element,
  };

  render() {
    return (
      <Tag href={this.props.href}>
        <IconWrapper>{this.props.icon}</IconWrapper> {this.props.label}
      </Tag>
    );
  }
}

export default Link;
