import React, { Component } from 'react';
import Background from './components/Background';
import Link from './components/Link';
import styled from 'styled-components';

const AppWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  font-size: 3vmin;
  font-family: 'Open Sans', sans-serif;
  line-height: 1;
  padding: 8vmin;
  color: #fff;
`;

const Name = styled.h1`
  font-size: 3em;
  font-weight: 700;
  line-height: inherit;
  margin: 0 0 2vmin 0;
`;

const Description = styled.p`
  margin: 0 0 2em 0;
`;

class App extends Component {
  render() {
    return (
      <AppWrapper>
        <Background />
        <Name>Marc Robichaud</Name>
        <Description>Developer, Designer, Inventor, Traveller</Description>
        <Link
          href="https://www.linkedin.com/in/mrobichaud/"
          class="linkedin"
          label="LinkedIn"
          icon={
            <svg
              role="img"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
            >
              <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
            </svg>
          }
        />
        <Link
          href="https://twitter.com/marcrobichaud"
          class="twitter"
          label="Twitter"
          icon={
            <svg
              role="img"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              fill="currentColor"
            >
              <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
            </svg>
          }
        />
        <Link
          href="https://www.facebook.com/marcvrobichaud"
          class="facebook"
          label="Facebook"
          icon={
            <svg
              role="img"
              viewBox="0 0 24 24"
              width="100%"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.408.593 24 1.324 24h11.494v-9.294H9.689v-3.621h3.129V8.41c0-3.099 1.894-4.785 4.659-4.785 1.325 0 2.464.097 2.796.141v3.24h-1.921c-1.5 0-1.792.721-1.792 1.771v2.311h3.584l-.465 3.63H16.56V24h6.115c.733 0 1.325-.592 1.325-1.324V1.324C24 .593 23.408 0 22.676 0" />
            </svg>
          }
        >
          Facebook
        </Link>
        <Link
          href="https://andofftheywent.tumblr.com/"
          class="tumblr"
          label="Tumblr"
          icon={
            <svg
              role="img"
              viewBox="0 0 24 24"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
            >
              <path d="M14.563 24c-5.093 0-7.031-3.756-7.031-6.411V9.747H5.116V6.648c3.63-1.313 4.512-4.596 4.71-6.469C9.84.051 9.941 0 9.999 0h3.517v6.114h4.801v3.633h-4.82v7.47c.016 1.001.375 2.371 2.207 2.371h.09c.631-.02 1.486-.205 1.936-.419l1.156 3.425c-.436.636-2.4 1.374-4.156 1.404h-.178l.011.002z" />
            </svg>
          }
        />
        <Link
          href="https://www.flickr.com/photos/sybarites/"
          class="flickr"
          label="Flickr"
          icon={
            <svg
              viewBox="0 0 362.68 362.68"
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              fill="currentColor"
            >
              <circle cx="85.8" cy="181.34" r="85.8" />
              <circle cx="276.88" cy="181.34" r="85.8" />
            </svg>
          }
        />
        <Link
          href="mailto:marc@studiosciences.ca"
          label="Email"
          class="email"
          icon={
            <svg
              width="100%"
              viewBox="0 0 80 56"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
            >
              <path d="M0 6l40 30L80 6v49.723H0z" />
              <path d="M40 30L80 0H0z" />
            </svg>
          }
        />
        <Link
          label="Patents"
          href="https://patents.google.com/?inventor=Marc+Robichaud&assignee=Splunk"
          class="patents"
          icon={
            <svg
              width="100%"
              viewBox="0 0 62 81"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
            >
              <path d="M8.74939953,51.1757408 C3.34244821,45.7497945 0,38.2652326 0,30 C0,13.4314575 13.4314575,0 30,0 C46.5685425,0 60,13.4314575 60,30 C60,37.7603679 57.0534138,44.8325236 52.2180179,50.1586909 L60.9110619,69.317328 C61.119408,69.7765028 61.1466246,70.2974297 60.9872628,70.7758159 C60.6381649,71.8237684 59.5056318,72.390302 58.4576793,72.0412042 L49.2763183,68.9826747 C48.3029105,68.6584094 47.2420139,69.1233205 46.8206596,70.058805 L42.6957019,79.2169728 C42.495956,79.6604456 42.1414504,80.015932 41.6985311,80.2169023 C40.6926631,80.673305 39.5072585,80.2278754 39.0508558,79.2220075 L30.7140692,60.8485277 L24.16462,75.5588315 C23.7153511,76.567906 22.5331302,77.0217181 21.5240558,76.5724492 C21.0752672,76.3726356 20.7157177,76.0146354 20.513968,75.5667138 L16.4062824,66.4468933 C15.9849281,65.5114088 14.9240315,65.0464978 13.9506237,65.370763 L4.72763599,68.4431593 C3.67968349,68.7922572 2.54715038,68.2257236 2.19805251,67.1777711 C2.04023548,66.7040221 2.06534602,66.1883735 2.26844746,65.7322002 L8.74940102,51.1757401 Z M30.5,49 C40.7172679,49 49,40.7172679 49,30.5 C49,20.2827321 40.7172679,12 30.5,12 C20.2827321,12 12,20.2827321 12,30.5 C12,40.7172679 20.2827321,49 30.5,49 Z" />
            </svg>
          }
        />
      </AppWrapper>
    );
  }
}

export default App;
